import React, { CSSProperties } from 'react';

const CircleIcon = ({ className, style }: { className?: string | undefined, style?: CSSProperties | undefined }) => {
    return (
        <svg style={style} className={className} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <circle cx="12" cy="12" r="10" fill="currentColor"></circle >
        </svg>
    )
};

export default CircleIcon;