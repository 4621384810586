import { IconButton, Link, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { FormatListNumberedRtl, Help, LocalCafe } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import React, { FC } from 'react';
import smallLogo from '../assets/img/fm-logo-sm-icon.png';
import AppNavigation from '../Routes/AppNavigation';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: '56px'
    },
    menuButton: {},
    title: {
      flexGrow: 1,
    },
    menuIcon: {
      verticalAlign: 'middle',
      minWidth: '24px',
    },
    logo: {
      verticalAlign: 'middle',
    },
  })
);

const StyledRouterLink = withStyles({
  root: {
    width: '100%',
  },
})(RouterLink);
const StyledLink = withStyles({
  root: {
    width: '100%',
  },
})(Link);

const RootLayout: FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              aria-controls="nav-menu"
              aria-haspopup="true"
              onClick={handleClick}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Menu id="nav-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem component={StyledRouterLink} color="textPrimary" to="/" onClick={handleClose}>
                <ListItemIcon className={classes.menuIcon}>
                  <FormatListNumberedRtl fontSize="small" />
                </ListItemIcon>{' '}
                Token Tracker
              </MenuItem>
              <MenuItem component={StyledRouterLink} style={{ width: '100%' }} color="textPrimary" to="/help" onClick={handleClose}>
                <ListItemIcon className={classes.menuIcon}>
                  <Help fontSize="small" />
                </ListItemIcon>{' '}
                Help
              </MenuItem>
              <MenuItem
                component={StyledLink}
                onClick={handleClose}
                color="textPrimary"
                href="https://www.buymeacoffee.com/frenziedmarmot"
                target="_blank"
                rel="noreferrer"
              >
                <ListItemIcon className={classes.menuIcon}>
                  <LocalCafe fontSize="small" />
                </ListItemIcon>{' '}
                Buy me a coffee
              </MenuItem>
            </Menu>
            <Typography variant="h6" className={classes.title}>
              Token Tracker
            </Typography>
            <a href="https://frenziedmarmot.com" target="_blank" rel="noreferrer">
              <img src={smallLogo} className={classes.logo} alt="Frenzied Marmot logo" />
            </a>
          </Toolbar>
        </AppBar>
      </div>
      <AppNavigation />
    </>
  );
};

export default RootLayout;
