import { LinearProgress } from '@material-ui/core';
import React, { FC, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import lazyImport from '../lazy-import';
import Help from '../Screens/Help/help';
import Page404 from '../Screens/Page404';

const Landing = lazyImport('../Screens/Landing');

const AppNavigation: FC = () => {
  return (
    <>
      <Suspense fallback={<LinearProgress />}>
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/help" exact component={Help} />
          <Route path="/*" exact component={Page404} />
        </Switch>
      </Suspense>
    </>
  );
};

export default AppNavigation;
