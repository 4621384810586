import { Typography } from '@material-ui/core';
import React, { FC } from 'react';

const Page404: FC = () => {
  return (
    <div>
      <Typography variant="h2">404: Page Not Found</Typography>
    </div>
  );
};

export default Page404;
