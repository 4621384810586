import React, { FC } from 'react';
import { tokenMap } from '../../Components/token-map/token-map';
import styles from './help.module.scss';
import { TokenKey } from '../../Models/TokenKey';
import { DeleteForever, DragIndicator, FormatColorFill, MoreVert, Remove } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { Card, CardContent, CardHeader, withStyles } from '@material-ui/core';

const appName = 'Token Tracker';

const ShapeTokens = () => (
  <div className={styles.tokenShowcase}>
    {Object.keys(tokenMap).map((key) =>
      key !== 'alpha' ? (
        <div key={key} className={styles.token}>
          {React.cloneElement(tokenMap[key as TokenKey])}
        </div>
      ) : (
        ''
      )
    )}
  </div>
);

const iconStyle = { fontSize: '14px', verticalAlign: 'text-bottom' };
const buttonIconStyle = { ...iconStyle, border: '1px solid #CCCCCC', borderRadius: '3px' };

const HelpCard = withStyles({
  root: {
    marginBottom: '16px',
    '& p': {
      marginTop: 0,
    },
  },
})(Card);

const AlphaTokens = () => (
  <div className={styles.tokenShowcase}>
    {Object.keys(tokenMap).map((key) =>
      key === 'alpha' ? (
        <div key={key} className={styles.token}>
          {React.cloneElement(tokenMap[key as TokenKey])}
        </div>
      ) : (
        ''
      )
    )}
  </div>
);

export const Help: FC = () => {
  function getInlineToken(name: TokenKey, color: string) {
    return (
      <span className={styles.tokenInline} style={color ? { color } : {}}>
        {React.cloneElement(tokenMap[name])}
      </span>
    );
  }

  return (
    <div className={styles.helpPage}>
      <h1>Help</h1>
      <h2>What is {appName} for?</h2>
      <HelpCard>
        <CardHeader title="Tracking tokens and keeping score" />
        <CardContent>
          <p>
            Tokens are typically in board games to simply count how much of something you have. They can range from cardboard "coins" to
            represent how much gold you have to painted wooden cubes or glass beads. <em>{appName}</em> is for tracking these tokens using
            an app instead of using physical tokens. This helps to:
          </p>
          <ul>
            <li>De-clutter your table from mundane tokens</li>
            <li>Reduce having to reach over the board to reduce the risk of disrupting play</li>
            <li>Avoid having to re-count or re-calculate</li>
          </ul>
          <p>Some example usages are:</p>
          <ul>
            <li>Points</li>
            <li>Gold</li>
            <li>Health</li>
            <li>Anything else that is represented by having X amount of some color or shape!</li>
          </ul>
          <p>You can even use text tokens for names to keep score in your favorite dice or card game!</p>
        </CardContent>
      </HelpCard>

      <h2>How do you use it?</h2>
      <HelpCard>
        <CardHeader title="Token Sets" />
        <CardContent>
          <p>
            Token sets are a set of tokens that belong together. Typically you'd have a set for each game or variation you'd find the app
            useful on. The name of the set is displayed at the top of the app. If you haven't named it, it simply says "Untitled Token Set".
            To name or rename set, tap on the placeholder or existing name to bring up a text field.
          </p>
          <p>
            To the right of the name is the token set menu (<MoreVert style={iconStyle} />
            ). From this menu you can:
          </p>
          <ul>
            <li>Save your set for later</li>
            <li>Clone a saved set to a new set (useful for games with variations)</li>
            <li>Create a new set, clearing the current tokens</li>
            <li>Set the current token counts to zero</li>
            <li>
              Delete a saved set (<DeleteForever color="error" style={iconStyle} />)
            </li>
            <li>Navigate to a saved set</li>
          </ul>
        </CardContent>
      </HelpCard>
      <HelpCard>
        <CardHeader title="Adding Tokens" />
        <CardContent>
          <p>
            The game has 2 categories of token which can be combined with a color. Start by looking at the bottom of the tracker screen and
            tapping the color icon (<FormatColorFill style={iconStyle} />) and selecting the color you'd like your token to be. Then select
            the token you want from the "Token" dropdown. To the right, you'll find the "Add Token" button which adds it to the current
            token set. The "Add Token" button also displays a preview of what the token will look like.
          </p>
          <div className={styles.tokenHeader}>
            <h4>Shape tokens</h4>
            <ShapeTokens />
          </div>
          <p>
            Shape tokens are merely represented by a color and shape. It could be an orange {getInlineToken('square', 'orangered')} or a red{' '}
            {getInlineToken('heart', 'red')}, you choose!
          </p>
          <div className={styles.tokenHeader}>
            <h4>The Text Token</h4>
            <AlphaTokens />
          </div>
          <p>
            The text token is selectable by its token appearance in the picker on the bottom of the screen (
            {getInlineToken('alpha', 'white')}) and still ends up being the color you choose. However, after you add the token by tapping
            "Add Token" it will display a text field instead of an icon with the placeholder of "Enter Text". Tap the text field to change
            the text.
          </p>
        </CardContent>
      </HelpCard>
      <HelpCard>
        <CardHeader title="Keeping Count" />
        <CardContent>
          <p>
            When you add a token, each token starts at zero. Clicking the <AddIcon style={buttonIconStyle} /> or the{' '}
            <Remove style={buttonIconStyle} /> will increase or decrease the count by 1.
          </p>
          <h4>Setting the count directly</h4>
          <p>
            Tap on a token's count will bring up a text field. Input any whole-number value without any +/- sign. It will be saved to that
            number the moment the textfield loses focus.
          </p>
          <h4>Doing math</h4>
          <p>
            If you tap on the count and instead of inputting a simple whole-number value you start it with an operator (+, -, /, *) it will
            do the math based on the existing value. For example, if the existing count is <code>10</code> and you enter <code>+10</code>,
            the new count will be <code>20</code>. If the count is <code>5</code> and you type <code>-10</code>, the new count will be{' '}
            <code>-5</code>. There can be a positive/negative sign after the operator. For example, if the count is <code>-10</code> and you
            enter <code>*-2</code> then the end result will be <code>-10 * -2 = 20</code>.
          </p>
          <p>
            <em>
              Note: Because we can't differentiate between subtracting and direct input of negative numbers it means we will always treat it
              as subtraction! It's the tradeoff for allowing math.
            </em>
          </p>
        </CardContent>
      </HelpCard>
      <HelpCard>
        <CardHeader title="Re-ordering tokens" />
        <CardContent>
          <p>
            On the left side of each token in a set, will be a drag icon (<DragIndicator style={iconStyle} />
            ). Tap and hold to drag the icon up/down. Let go of the token to assign it a new place in the order.
          </p>
        </CardContent>
      </HelpCard>
      <HelpCard>
        <CardHeader title="Deleting tokens" />
        <CardContent>
          <p>
            On the left side of each token in a set, will be a drag icon (<DragIndicator style={iconStyle} />
            ). Tap and hold to drag the icon, then bring it down to the very bottom panel where a delete icon will have appeared (
            <span className={styles.deletePanel}>
              <DeleteForever style={iconStyle} />
            </span>
            ). When hovering over the panel, it will change to dark red (
            <span className={styles.deletePanelHovered}>
              <DeleteForever style={iconStyle} />
            </span>
            ). Drop the token on the red deletion panel to delete the token.
          </p>
        </CardContent>
      </HelpCard>
    </div>
  );
};

export default Help;
