import { Favorite, FontDownload, MenuBook, Security } from '@material-ui/icons';
import React from 'react';
import CircleIcon from '../../assets/img/circle-icon';
import PaymentsIcon from '../../assets/img/payments-icon';
import SquareIcon from '../../assets/img/square-icon';
import TriangleIcon from '../../assets/img/triangle-icon';
import TrophyIcon from '../../assets/img/trophy-icon';
import { TokenKey } from '../../Models/TokenKey';
import styles from './token-map.module.scss';

export const tokenMap: { [key in TokenKey] : JSX.Element } = {
  square: <SquareIcon className={styles.svgToken} />,
  triangle: <TriangleIcon className={styles.svgToken} />,
  circle: <CircleIcon className={styles.svgToken} />,
  heart: <Favorite className={styles.svgToken} />,
  shield: <Security className={styles.svgToken} />,
  book: <MenuBook className={styles.svgToken} />,
  victory: <TrophyIcon className={styles.svgToken} />,
  gold: <PaymentsIcon className={styles.svgToken} />,
  alpha: <FontDownload className={styles.svgToken} />,
};

export default tokenMap;
