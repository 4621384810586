import { Button, CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Cancel } from '@material-ui/icons';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Boot from './Boot';
import RootLayout from './Screens/RootLayout';
import Theme from './Theme';
import './App.scss';

const CloseButton = ({ closeToast }: { closeToast: any }) => (
  <Button
    variant="outlined"
    aria-label="Dismiss"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      closeToast(e);
    }}
  >
    <Cancel />&nbsp;Cancel
  </Button>
);

const App: React.FC = () => {
  Boot().then(() => { /* no-op */ });
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <RootLayout />
        <ToastContainer closeButton={CloseButton} />
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

export default App;
