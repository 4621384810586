import React, { CSSProperties } from 'react';

const SquareIcon = ({ className, style }: { className?: string | undefined, style?: CSSProperties | undefined }) => {
    return (
        <svg style={style} className={className} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M2 2h20v20H2z" fill="currentColor"></path>
        </svg>
    )
};

export default SquareIcon;
